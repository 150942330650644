import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                <span>| </span>
                <a href="/nordic-berry/" className="link">
                  Nordic Berry
                </a>
                <span />
                <span>|</span>
                <a href="/plet-s-nedokonalostmi/">Pleť s nedokonalostmi</a>
                <span />
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/66/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup>
                  </p>
                  <p>
                    <span>
                      <span>
                        Norská receptura Visibly Renew denní tělový balzám
                      </span>
                    </span>
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NEUTROGENA-visibly-rebnew-balsam-250x220.jpg"
                      alt="NEUTROGENA visibly rebnew balsam 250x220"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup>
                    </p>
                    <p>
                      <span>
                        <span>
                          Norská receptura Visibly Renew denní tělový balzám
                        </span>
                      </span>
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        <span data-mce-mark="1">
                          <span data-mce-mark="1">
                            NEUTROGENA<span data-mce-mark="1">®</span>
                            &nbsp;Norská receptura Visibly Renew denní tělový
                            balzám nejen poskytuje dlouhotrvající výživu, ale
                            také pomáhá obnovit pružnost pokožky. Rozdil, který
                            můžete vidět a cítit.{" "}
                          </span>
                          <span data-mce-mark="1">
                            S Norskou recepturou intenzivně hydratuje a aktivní
                            minerály napomáhají posílit tvorbu pro pokožku
                            vlastního kolagenu.*{" "}
                          </span>
                        </span>
                      </p>
                      <p>
                        <span data-mce-mark="1">
                          <span data-mce-mark="1">* Výsledky in vitro.</span>
                        </span>
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Pro dosažení nejlepších výsledků používejte ráno a večer.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>200 ml</dd>
                  </dl>
                </div>
                <div id="fb_27"></div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobrazit související produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/83/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-balzam-na-rty-regeneracni.jpg"
                      alt="NEUTROGENA balzam na rty regeneracni"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>&nbsp;Norská receptura Intenzivní
                        regenerační balzám na rty
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/85/"
                  >
                    <img
                      src="/assets/Uploads/Visibly-Clear-PoreShine-Maska-do-sprchy6.jpg"
                      alt="Visibly Clear PoreShine Maska do sprchy6"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>&nbsp;VISIBLY CLEAR
                        <sup>®&nbsp;</sup>Pore &amp; Shine Pleťová maska do
                        sprchy
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/90/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-HydroBoost-cistici-gel-250x.png"
                      alt="NEUTROGENA HydroBoost cistici gel 250x"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Čistící gel
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/91/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-HydroBoost-odlicovaci-mleko-200ml-250x.png"
                      alt="NEUTROGENA HydroBoost odlicovaci mleko 200ml 250x"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Odličovací pleťové mléko
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/92/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-HydroBoost-krem-na-oci-15ml-500x.png"
                      alt="NEUTROGENA HydroBoost krem na oci 15ml 500x"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Rozjasňující oční krém
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
